<template>
  <div class="article">
    <div class="contents tbl_wrap">
      <div class="tbl_option">
        <h5>원가산출</h5>
        <button class="btn_sub1" value="lot" @click="checkButton($event)">
          작업지시서 검색
        </button>
        <div>
          <button
            class="btn_sub1"
            value="barcode"
            @click="barcodeButton($event)"
          >
            번호 검색
          </button>
          <div class="input_radio">
            <div>
              <label
                for="radio1"
                class="label"
                :class="{ active: radioState.radio_lot }"
                @click="
                  () => {
                    $store.commit('setRadioStateToLotTracking', 'lot');
                  }
                "
              ></label>
              <input
                type="radio"
                id="radio1"
                name="search_opt"
                :checked="radioState.radio_lot"
              />
              <label for="radio1" class="label_txt">LOT 번호</label>
            </div>
            <div>
              <label
                for="radio2"
                class="label"
                :class="{ active: radioState.radio_barcode }"
                @click="
                  () => {
                    $store.commit('setRadioStateToLotTracking', 'barcode');
                  }
                "
              ></label>
              <input
                type="radio"
                id="radio2"
                name="search_opt"
                :checked="radioState.radio_barcode"
              />
              <label for="radio2" class="label_txt">제품식별 번호</label>
            </div>
          </div>
        </div>
      </div>
      <div class="info_wrap" v-if="target_lot != null">
        <div class="order_info">
          <h5>
            작업지시 정보
            <span
              v-if="
                target_lot.lot_type_id ==
                  lotTypes.find(x => x.detail == 'done process').id
              "
              ><img src="@/assets/images/icon/flag-end.png" alt="작업완료"
            /></span>
            <span v-else
              ><img src="@/assets/images/icon/flag-procesing.png" alt="생산중"
            /></span>
          </h5>
          <div class="info_cont">
            <div>
              <div class="input_text">
                <label>LOT 번호</label>
                <input
                  id="lotNum"
                  type="text"
                  readonly
                  :value="`L${target_lot.lot_number}`"
                />
              </div>
              <div class="input_text">
                <label>작업수량(단위)</label>
                <input
                  type="text"
                  readonly
                  :value="
                    $makeComma(target_lot.quantity) +
                      '(' +
                      findInfoFromId(
                        units,
                        findInfoFromId(products, target_lot.product_id)
                          .stock_unit_id,
                      ).name +
                      ')'
                  "
                />
              </div>
            </div>
            <div>
              <div class="input_text">
                <label>지시일자</label>
                <input type="text" readonly :value="target_lot.input_date" />
              </div>
              <div class="input_text">
                <label>납품예정일</label>
                <input type="text" readonly :value="target_lot.deadline_date" />
              </div>
            </div>
          </div>
        </div>
        <div class="production_info">
          <h5>생산정보</h5>
          <div class="info_cont">
            <div>
              <div class="input_text ipt_bom">
                <label>생산제품</label>
                <input
                  type="text"
                  readonly
                  :value="findInfoFromId(products, target_lot.product_id).name"
                />
                <button
                  class="btn_sub2"
                  @click="goBomManagementPage(target_lot.product_id)"
                  v-if="target_lot.product_id != null"
                >
                  BOM
                </button>
              </div>
              <div class="input_text ipt_ea">
                <label>생산수량(단위)</label>
                <input
                  type="text"
                  readonly
                  :value="
                    $makeComma(getMinPassQuantity(target_lot.bom_info)) +
                      '(' +
                      findInfoFromId(
                        units,
                        findInfoFromId(products, target_lot.product_id)
                          .stock_unit_id,
                      ).name +
                      ')'
                  "
                />
              </div>
            </div>
            <div class="input_text ipt_date">
              <label>생산일자</label>
              <div>
                <div class="input_text">
                  <input
                    type="text"
                    readonly
                    :value="
                      getStartTime +
                        (getEndTime != null ? ' ~ ' + getEndTime : '')
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h5 v-if="target_lot != null">원가 정보</h5>
      <!-- <div class="tab_wrap" v-if="target_lot != null"> -->
      <div class="tab_wrap" v-if="refineTrackingResource.length != 0">
        <div class="tab_list">
          <ul>
            <li
              :class="{ active: selectedTab == index }"
              v-for="(groupResource, index) in refineTrackingResource"
              :key="index"
              @click="
                () => {
                  $store.commit('setSelectedTabToCostCalculation', index);
                }
              "
              :data-tab="`tab${selectedTab}`"
            >
              {{ findInfoFromId(process, groupResource.process_id).name }}
            </li>
          </ul>
        </div>
        <div class="tab_cont">
          <div :class="`tab${selectedTab}`" class="cost_calculation">
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <colgroup>
                  <col v-for="(n, index) in 8" :key="index" />
                </colgroup>
                <thead>
                  <tr>
                    <th colspan="2">
                      <b>{{
                        findInfoFromId(
                          process,
                          refineTrackingResource[selectedTab].element[0]
                            .process_id,
                        ).name
                      }}</b>
                      <!-- // 여기서 undefined -->
                    </th>
                    <th colspan="6">투입정보</th>
                  </tr>
                  <tr>
                    <th>입고일</th>
                    <th>구매처</th>
                    <th>투입자재</th>
                    <th>1,000g당 단가</th>
                    <th>투입수량(단위)</th>
                    <th>비율(%)</th>
                    <th>1개당 재료단가</th>
                    <th>
                      {{
                        `총 투입 비용 (${$makeComma(target_lot.quantity) +
                          findInfoFromId(
                            units,
                            findInfoFromId(products, target_lot.product_id)
                              .stock_unit_id,
                          ).name})`
                      }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in refineTrackingResource[selectedTab].element"
                    :key="item.id"
                  >
                    <td>{{ item.input_time | formatDateNoHours }}</td>
                    <td class="text_left">
                      {{ findInfoFromId(companys, item.company_id).name }}
                    </td>
                    <td class="text_left">
                      <b
                        >{{ findInfoFromId(materials, item.material_id).name
                        }}{{
                          findInfoFromId(
                            materials,
                            item.material_id,
                          ).standard.trim() != '' &&
                          findInfoFromId(materials, item.material_id)
                            .standard != null
                            ? ` - ${
                                findInfoFromId(materials, item.material_id)
                                  .standard
                              }`
                            : ''
                        }}
                      </b>
                    </td>
                    <td class="text_right">
                      {{
                        item.kgCost != null
                          ? item.kgCost == 0
                            ? ''
                            : $makeComma(item.kgCost)
                          : '산출불가'
                      }}
                    </td>
                    <td class="text_right">
                      <b>{{
                        item.material_id != null
                          ? `${$makeComma(
                              $decimalDiv(
                                item.quantity,
                                getUnitConversion(item.material_id),
                              ),
                            )}`
                          : `${$makeComma(item.quantity)}`
                      }}</b>
                      {{ `(${findInfoFromId(units, item.unit_id).name})` }}
                    </td>
                    <td>
                      {{
                        item.percent != null
                          ? $makeComma($makeNumberFixed2(item.percent)) + '%'
                          : '산출불가'
                      }}
                    </td>
                    <td class="text_right">
                      {{
                        item.cost == 0 || item.quantity == 0
                          ? ''
                          : $makeComma(
                              $decimalDiv(
                                $decimalMul(item.quantity, item.cost),
                                target_lot.quantity,
                              ),
                            )
                      }}
                    </td>
                    <td class="text_right">
                      {{
                        item.cost == 0 || item.quantity == 0
                          ? ''
                          : $makeComma($decimalMul(item.quantity, item.cost))
                      }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="text_right" colspan="4">총량</td>
                    <td class="text_right">
                      <b>{{
                        $makeComma(
                          refineTrackingResource[selectedTab].element
                            .map(x => x.g_quantity)
                            .reduce((a, b) => $decimalAdd(a, b), 0),
                        )
                      }}</b>
                      (g)
                    </td>
                    <td>100%</td>
                    <td class="text_right">
                      <b>{{
                        $makeComma(
                          refineTrackingResource[selectedTab].element
                            .map(x =>
                              $decimalDiv(
                                $decimalMul(x.quantity, x.cost),
                                target_lot.quantity,
                              ),
                            )
                            .reduce((a, b) => $decimalAdd(a, b), 0),
                        )
                      }}</b>
                    </td>
                    <td class="text_right">
                      <b>{{
                        $makeComma(
                          refineTrackingResource[selectedTab].element
                            .map(x => $decimalMul(x.quantity, x.cost))
                            .reduce((a, b) => $decimalAdd(a, b), 0),
                        )
                      }}</b>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <lot-search
      v-if="showLotSearch"
      :filterWaiting="true"
      @onclose="showLotSearch = false"
      @onselect="
        $event => {
          selectLot($event);
          showLotSearch = false;
        }
      "
    ></lot-search>
    <barcode-read
      v-if="showBarcodeSearch"
      :fiterWauting="true"
      :isLot="radioState.radio_lot"
      @onselect="
        $event => {
          searchProductBarcode($event);
          showBarcodeSearch = false;
        }
      "
      @onclose="showBarcodeSearch = false"
    >
    </barcode-read>
    <div
      class="modal_layer"
      v-if="showBarcodeSearch"
      @click="showBarcodeSearch = false"
    ></div>

    <two-button-modal
      :modal_title="'검색 실패'"
      :modal_content="
        '입력한 제품 식별표의 생산공정이 조회되지 않습니다. LOT번호로 조회하시겠습니까?'
      "
      :isModalOpen="showTwoButtonModal"
      @onclose="showTwoButtonModal = false"
      @OnYesClick="
        showTwoButtonModal = false;
        searchLot(lot_number_temp);
      "
    ></two-button-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FetchMixin from '@/mixins/fetch';
import LotSearch from '@/layouts/components/search-popup/LotSearch';
import { formatDate } from '@/utils/filters';
import routes from '@/routes/routes';
import barcodeRead from '@/layouts/components/BarcodeReadModal';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';

export default {
  mixins: [SpinnerMixin, ModalMixin, FetchMixin],
  data() {
    return {
      showLotSearch: false,
      showBarcodeSearch: false,
      showTwoButtonModal: false,
      lot_number_temp: null,
    };
  },
  components: {
    LotSearch,
    barcodeRead,
    TwoButtonModal,
  },
  computed: {
    ...mapGetters({
      target_lot: 'getTargetLotFromCostCalculation',
      tracking_resource: 'getCostCalculationResource',
      tracking_sales: 'getCostCalculationSales',
      searchText: 'getSearchTextFromCostCalculation',
      radioState: 'getRadioStateFromLotTracking',
      selectedTab: 'getSelectedTabFromCostCalculation',
      unitConversions: 'getUnitConversion',
      products: 'getProduct',
      process: 'getProcess',
      companys: 'getCompany',
      units: 'getUnitCodes',
      materials: 'getMaterial',
      lotTypes: 'getLotType',
    }),
    refineTrackingResource() {
      if (this.tracking_resource.length < 1) {
        return [];
      } else {
        const listTemp = this.lodash.clonedeep(this.tracking_resource);
        listTemp.sort((a, b) => a.process_id - b.process_id);

        const obj = listTemp.reduce(function(rv, x) {
          (rv[x['process_id']] = rv[x['process_id']] || []).push(x);
          return rv;
        }, {});

        let arr = [];
        for (let k in obj) {
          const g_quantity = obj[k]
            .filter(x =>
              ['G', 'g'].includes(
                this.findInfoFromId(this.units, x.purchase_material_unit_id)
                  .name,
              ),
            )
            .map(x => x.quantity)
            .reduce((a, b) => this.$decimalAdd(a, b), 0);
          const kg_quantity = obj[k]
            .filter(x =>
              ['Kg', 'KG', 'kg'].includes(
                this.findInfoFromId(this.units, x.purchase_material_unit_id)
                  .name,
              ),
            )
            .map(x => this.$decimalMul(x.quantity, 1000))
            .reduce((a, b) => this.$decimalAdd(a, b), 0);
          const all_quantity = this.$decimalAdd(g_quantity, kg_quantity);
          obj[k].map(x => {
            const cvr_ratio = this.getUnitConversion(x.material_id);
            x.cal_cost = this.$decimalMul(x.cost, cvr_ratio);
            x.in_unit = this.findInfoFromId(
              this.materials,
              x.material_id,
            ).incoming_unit_id;
            x.out_unit = this.findInfoFromId(
              this.materials,
              x.material_id,
            ).using_unit_id;
            x.g_quantity = 0;
            if (
              ['KG', 'Kg', 'kg'].includes(
                this.findInfoFromId(this.units, x.purchase_material_unit_id)
                  .name,
              )
            ) {
              x.g_quantity = this.$decimalMul(x.quantity, 1000);
            } else if (
              ['G', 'g'].includes(
                this.findInfoFromId(this.units, x.purchase_material_unit_id)
                  .name,
              )
            ) {
              x.g_quantity = x.quantity;
            }
            if (x.g_quantity == 0) {
              x.percent = 0;
            } else {
              x.percent = this.$decimalMul(
                this.$decimalDiv(x.g_quantity, all_quantity),
                100,
              );
            }
            x.kgCost = null;
            if (
              ['KG', 'Kg', 'kg'].includes(
                this.findInfoFromId(this.units, x.purchase_material_unit_id)
                  .name,
              )
            ) {
              x.kgCost = x.cost;
            } else if (
              ['G', 'g'].includes(
                this.findInfoFromId(this.units, x.purchase_material_unit_id)
                  .name,
              )
            ) {
              x.kgCost = this.$decimalMul(x.cost, 1000);
            }
            return x;
          });
          arr.push({
            process_id: k,
            element: obj[k].sort((a, b) => b.g_quantity - a.g_quantity),
          });
        }
        return arr;
      }
    },
    getStartTime() {
      if (this.target_lot != null && this.target_lot.bom_info != undefined) {
        const BomProcess = this.target_lot.bom_info.bom_process;
        const ProductionInfoList = this.lodash
          .clonedeep(BomProcess.map(x => x.production_info))
          .filter(x => x != null);

        ProductionInfoList.sort((a, b) => {
          Number(
            a.create_time
              .replace(/-/gi, '')
              .replace(/:/gi, '')
              .replace(' ', ''),
          ) -
            Number(
              b.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            );
        });

        return formatDate(ProductionInfoList[0].create_time);
      } else {
        return null;
      }
    },
    getEndTime() {
      if (this.target_lot != null && this.target_lot.product_in != undefined) {
        return formatDate(
          this.target_lot.product_in[this.target_lot.product_in.length - 1]
            .create_time,
        );
      } else {
        return '';
      }
    },
  },
  methods: {
    getUnitConversion(material_id) {
      const target_mat = this.findInfoFromId(this.materials, material_id);
      const foundItem = this.unitConversions.find(
        x =>
          x.in_unit_id == target_mat.incoming_unit_id &&
          x.out_unit_id == target_mat.using_unit_id,
      );
      if (foundItem != null) {
        return foundItem.cvr_ratio;
      } else {
        return 1;
      }
    },
    checkButton() {
      this.showLotSearch = true;
      this.showBarcodeSearch = false;
    },
    barcodeButton() {
      this.showLotSearch = false;
      this.showBarcodeSearch = true;
    },
    async trackingProductStock(item) {
      const goingPage = routes[0].children.find(
        x => x.path == '/product/status',
      );
      if (goingPage != undefined) {
        goingPage.meta.stock = this.lodash.clonedeep(item);
        await this.$router.push('/product/status');
      }
    },
    async trackingStatus(item) {
      const goingPage = routes[0].children.find(
        x => x.path == `/${item.resource_gubun}/status`,
      );
      if (goingPage != undefined) {
        goingPage.meta.target = this.lodash.clonedeep(item);
        await this.$router.push(`/${item.resource_gubun}/status`);
      }
    },
    typing(e) {
      this.$store.commit('setSearchTextToCostCalculation', e.target.value);
    },
    async searchLot(lot_no) {
      if (lot_no.length == 13) {
        let lot_str = lot_no.substr(8);
        lot_str = Number(lot_str);
        this.selectLot({ id: lot_str });
      } else {
        this.openOneButtonModal(
          '올바른 LOT번호를 입력해주세요',
          '작업지시서로 검색할 수 있습니다.',
        );
      }
    },
    async searchProductBarcode(control_no) {
      if (control_no.length == 13) {
        let control_str = control_no.substr(8);
        control_str = Number(control_str);
        if (this.radioState.radio_lot == true) {
          this.selectLot({ id: Number(control_str) });
        } else if (this.radioState.radio_barcode == true) {
          await this.$store
            .dispatch('FIND_LOT_ID_FROM_CONTROL_ID', Number(control_str))
            .then(id => {
              if (id == -1) {
                this.openOneButtonModal(
                  '검색 실패',
                  '입력한 제품 식별표의 생산공정이 조회되지 않습니다.',
                );
              } else {
                this.selectLot({ id: id });
              }
            })
            .catch(() => {
              this.lot_number_temp = control_no;
              this.showTwoButtonModal = true;
            });
        }
      } else {
        this.openOneButtonModal(
          '올바른 LOT번호를 입력해주세요',
          '작업지시서로 검색할 수 있습니다.',
        );
      }
    },

    async selectLot(arg) {
      await this.FETCH_LOT_PRODUCTION_TO_COST_CALCULATION(arg.id);
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_COST_CALCULATION_RESOURCE_WITH_COST',
        arg.id,
        '투입',
      );
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_COST_CALCULATION_SALES',
        arg.id,
        '납품',
      );
    },
    async FETCH_LOT_PRODUCTION_TO_COST_CALCULATION(lot_id) {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_LOT_PRODUCTION_TO_COST_CALCULATION', lot_id)
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 실패',
            '잘못된 번호입니다. 올바른 번호를 입력해 주세요.',
          );
          this.$store.commit('setTargetLotToCostCalculation', null);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    goBomManagementPage(product_id) {
      const goingPage = routes[0].children.find(x => x.path == '/code/bom');
      if (goingPage != undefined) {
        goingPage.meta.select = product_id;
      }
      this.$router.push('/code/bom');
    },
    getMinPassQuantity(bom_info) {
      if (bom_info != undefined) {
        let max_index = 0;
        bom_info.bom_process.forEach(el => {
          if (el.index > max_index) max_index = el.index;
        });
        console.log('max_index', max_index);
        let min_pass_quantity = 99999999999999;
        const filterLastIndexProcess = this.lodash.clonedeep(
          bom_info.bom_process.filter(x => x.index == max_index),
        );
        filterLastIndexProcess.forEach(el => {
          if (el.production_info == null) min_pass_quantity = 0;
          else if (el.production_info.pass_quantity < min_pass_quantity)
            min_pass_quantity = el.production_info.pass_quantity;
        });

        return min_pass_quantity;
      } else {
        return 0;
      }
    },
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.process.length < 1) {
      await this.FETCH('FETCH_PROCESS', '공정');
    }
    if (this.lotTypes.length < 1) {
      await this.FETCH('FETCH_LOT_TYPE', '작업상태');
    }
    if (this.unitConversions.length < 1) {
      await this.FETCH('FETCH_UNIT_CONVERSION', '단위환산');
    }
    if (this.$route.meta.select != undefined) {
      this.selectLot({
        id: this.$route.meta.select,
      });
      this.$store.commit('setOpenTabIndexToLotTrackingPage', 0);
    }
  },
};
</script>

<style lang="scss" scoped></style>
