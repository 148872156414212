<template>
  <div class="article">
    <div class="contents tbl_wrap">
      <div class="tbl_option">
        <h5>제품 LOT추적</h5>
        <button class="btn_sub1" value="lot" @click="checkButton($event)">
          작업지시서 검색
        </button>

        <button
          class="btn_sub2 btn_print"
          @click.prevent="isProductionLogFormModalOpen = true"
          v-show="target_lot != null && target_lot.lot_type_id == 3"
        >
          <i class="ico_print"></i>생산일지 출력
        </button>
        <div>
          <button
            class="btn_sub1"
            value="barcode"
            @click="barcodeButton($event)"
          >
            번호 검색
          </button>
          <div class="input_radio">
            <div>
              <label
                for="radio1"
                class="label"
                :class="{ active: radioState.radio_lot }"
                @click="
                  () => {
                    $store.commit('setRadioStateToLotTracking', 'lot');
                  }
                "
              ></label>
              <input
                type="radio"
                id="radio1"
                name="search_opt"
                :checked="radioState.radio_lot"
              />
              <label for="radio1" class="label_txt">LOT 번호</label>
            </div>
            <div>
              <label
                for="radio2"
                class="label"
                :class="{ active: radioState.radio_barcode }"
                @click="
                  () => {
                    $store.commit('setRadioStateToLotTracking', 'barcode');
                  }
                "
              ></label>
              <input
                type="radio"
                id="radio2"
                name="search_opt"
                :checked="radioState.radio_barcode"
              />
              <label for="radio2" class="label_txt">제품식별 번호</label>
            </div>
          </div>
        </div>
      </div>
      <div class="info_wrap" v-if="target_lot != null">
        <div class="order_info">
          <h5>
            작업지시 정보
            <span
              v-if="
                target_lot.lot_type_id ==
                  lotTypes.find(x => x.detail == 'done process').id
              "
              ><img src="@/assets/images/icon/flag-end.png" alt="작업완료"
            /></span>
            <span v-else
              ><img src="@/assets/images/icon/flag-procesing.png" alt="생산중"
            /></span>
          </h5>
          <div class="info_cont">
            <div>
              <div class="input_text">
                <label>LOT 번호</label>
                <input
                  id="lotNum"
                  type="text"
                  readonly
                  :value="`L${target_lot.lot_number}`"
                />
              </div>
              <div class="input_text">
                <label>작업수량(단위)</label>
                <input
                  type="text"
                  readonly
                  :value="
                    $makeComma(target_lot.quantity) +
                      '(' +
                      findInfoFromId(
                        units,
                        findInfoFromId(products, target_lot.product_id)
                          .stock_unit_id,
                      ).name +
                      ')'
                  "
                />
              </div>
            </div>
            <div>
              <div class="input_text">
                <label>지시일자</label>
                <input type="text" readonly :value="target_lot.input_date" />
              </div>
              <div class="input_text">
                <label>납품예정일</label>
                <input type="text" readonly :value="target_lot.deadline_date" />
              </div>
            </div>
          </div>
        </div>
        <div class="production_info">
          <h5>생산정보</h5>
          <div class="info_cont">
            <div>
              <div class="input_text ipt_bom">
                <label>생산제품</label>
                <input
                  type="text"
                  readonly
                  :value="findInfoFromId(products, target_lot.product_id).name"
                />
                <button
                  class="btn_sub2"
                  @click="goBomManagementPage(target_lot.product_id)"
                  v-if="target_lot.product_id != null"
                >
                  BOM
                </button>
              </div>
              <div class="input_text ipt_ea">
                <label>생산수량(단위)</label>
                <input
                  type="text"
                  readonly
                  :value="
                    $makeComma(getMinPassQuantity(target_lot.bom_info)) +
                      '(' +
                      findInfoFromId(
                        units,
                        findInfoFromId(products, target_lot.product_id)
                          .stock_unit_id,
                      ).name +
                      ')'
                  "
                />
              </div>
            </div>
            <div class="input_text ipt_date">
              <label>생산일자</label>
              <div>
                <div class="input_text">
                  <input
                    type="text"
                    readonly
                    :value="
                      getStartTime +
                        (getEndTime != null ? ' ~ ' + getEndTime : '')
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab_wrap" v-if="target_lot != null">
        <div class="tab_list">
          <ul>
            <li
              :class="{ active: selectedTab == 0 }"
              @click="
                () => {
                  $store.commit('setSelectedTabToLotTracking', 0);
                }
              "
              data-tab="tab01"
            >
              공정&자재투입
            </li>
            <li
              :class="{ active: selectedTab == 1 }"
              @click="
                () => {
                  $store.commit('setSelectedTabToLotTracking', 1);
                }
              "
              data-tab="tab02"
              v-if="target_lot.product_in != undefined"
            >
              생산
            </li>
            <li
              :class="{ active: selectedTab == 2 }"
              @click="
                () => {
                  $store.commit('setSelectedTabToLotTracking', 2);
                }
              "
              data-tab="tab03"
              v-if="tracking_sales != undefined && tracking_sales.length > 0"
            >
              납품
            </li>
          </ul>
        </div>
        <div class="tab_cont">
          <div class="tab01" v-if="selectedTab == 0">
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <colgroup>
                  <col v-for="(n, index) in 5" :key="index" />
                </colgroup>
                <thead>
                  <tr>
                    <th colspan="2">공정명</th>
                    <th colspan="3">투입정보</th>
                  </tr>
                  <tr>
                    <th>공정명</th>
                    <th>공정등록시간</th>
                    <th>투입자재 or 제품</th>
                    <th>투입수량(단위)</th>
                    <th>식별코드</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tracking_resource" :key="item.id">
                    <td>
                      {{ findInfoFromId(process, item.process_id).name }}
                    </td>
                    <td>{{ item.create_time | formatDate }}</td>
                    <td class="text_left">
                      {{
                        item.resource_gubun == 'product'
                          ? findInfoFromId(products, item.product_id).name
                          : findInfoFromId(materials, item.material_id).name
                      }}{{
                        item.resource_gubun == 'product'
                          ? findInfoFromId(
                              products,
                              item.product_id,
                            ).standard.trim() != '' &&
                            findInfoFromId(products, item.product_id)
                              .standard != null
                            ? ` - ${
                                findInfoFromId(products, item.product_id)
                                  .standard
                              }`
                            : ''
                          : findInfoFromId(
                              materials,
                              item.material_id,
                            ).standard.trim() != '' &&
                            findInfoFromId(materials, item.material_id)
                              .standard != null
                          ? ` - ${
                              findInfoFromId(materials, item.material_id)
                                .standard
                            }`
                          : ''
                      }}
                    </td>
                    <td class="text_right">
                      <b>{{
                        item.material_id != null
                          ? `${$makeComma(
                              $decimalDiv(
                                item.quantity,
                                getUnitConversion(item.material_id),
                              ),
                            )}`
                          : `${$makeComma(item.quantity)}`
                      }}</b
                      >{{ ` (${findInfoFromId(units, item.unit_id).name})` }}
                    </td>
                    <td>
                      {{ item.ident_value }}

                      <button @click="trackingStatus(item)">현황 추적</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab02" v-if="selectedTab == 1">
            <div class="mes_tbl_wrap">
              <table class="mes_tbl" v-if="target_lot.product_in != undefined">
                <colgroup>
                  <col v-for="(n, index) in 4" :key="index" />
                </colgroup>
                <thead>
                  <tr>
                    <th>생산시간</th>
                    <th>
                      제품명{{ systemCompany.expire_yn ? ` (유통기한)` : '' }}
                    </th>
                    <th>생산식별표</th>
                    <th>수량(단위)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in target_lot.product_in" :key="item.id">
                    <td>{{ item.create_time | formatDate }}</td>
                    <td>
                      {{
                        findInfoFromId(products, item.product_id).name +
                          (findInfoFromId(
                            products,
                            item.product_id,
                          ).standard.trim() != '' &&
                          findInfoFromId(products, item.product_id).standard !=
                            null
                            ? ` - ${
                                findInfoFromId(products, item.product_id)
                                  .standard
                              }`
                            : '')
                      }}
                      {{
                        systemCompany.expire_yn
                          ? ` (${getExpireDate(item)} 까지)`
                          : ''
                      }}
                      <button @click="trackingProductStock(item.product_id)">
                        제품 재고 추적
                      </button>
                    </td>
                    <td>{{ `P${makeProductBarcode(item)}` }}</td>
                    <td>
                      {{
                        `${$makeComma(item.quantity)}(${
                          findInfoFromId(units, item.unit_id).name
                        })`
                      }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td>총 생산량</td>
                    <td></td>
                    <td></td>
                    <td>
                      {{
                        `${$makeComma(
                          target_lot.product_in
                            .map(x => x.quantity)
                            .reduce((a, b) => $decimalAdd(a, b), 0),
                        )}(${
                          findInfoFromId(
                            units,
                            target_lot.product_in[0].unit_id,
                          ).name
                        })`
                      }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="tab03" v-if="selectedTab == 2">
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <colgroup>
                  <col v-for="(n, index) in 5" :key="index" />
                </colgroup>
                <thead>
                  <tr>
                    <th>납품시간</th>
                    <th>제품명</th>
                    <th>납품처</th>
                    <th>상품코드</th>
                    <th>수량(단위)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in tracking_sales" :key="item.id">
                    <td>{{ item.create_time | formatDate }}</td>
                    <td>
                      {{ findInfoFromId(products, item.product_id).name
                      }}{{
                        findInfoFromId(
                          products,
                          item.product_id,
                        ).standard.trim() != '' &&
                        findInfoFromId(products, item.product_id).standard !=
                          null
                          ? ` - ${
                              findInfoFromId(products, item.product_id).standard
                            }`
                          : ''
                      }}
                    </td>
                    <td>
                      {{ findInfoFromId(companys, item.company_id).name }}
                    </td>
                    <td>{{ item.product_ident }}</td>
                    <td>
                      {{
                        `${$makeComma(item.quantity)}(${
                          findInfoFromId(
                            units,
                            findInfoFromId(products, item.product_id)
                              .stock_unit_id,
                          ).name
                        })`
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <lot-search
      v-if="showLotSearch"
      :filterWaiting="true"
      @onclose="showLotSearch = false"
      @onselect="
        $event => {
          selectLot($event);
          showLotSearch = false;
        }
      "
    ></lot-search>
    <barcode-read
      v-if="showBarcodeSearch"
      :isLot="radioState.radio_lot"
      @onselect="
        $event => {
          searchProductBarcode($event);
          showBarcodeSearch = false;
        }
      "
      @onclose="showBarcodeSearch = false"
    >
    </barcode-read>
    <div
      class="modal_layer"
      v-if="showBarcodeSearch"
      @click="showBarcodeSearch = false"
    ></div>
    <component
      :is="dynamicComponent"
      v-if="isProductionLogFormModalOpen"
      :productionDate="getStartTime + ' ~ ' + getEndTime"
      @onclose="productionPrintModalColse"
    >
    </component>

    <two-button-modal
      :modal_title="'검색 실패'"
      :modal_content="
        '입력한 제품 식별표의 생산공정이 조회되지 않습니다. LOT번호로 조회하시겠습니까?'
      "
      :isModalOpen="showTwoButtonModal"
      @onclose="showTwoButtonModal = false"
      @OnYesClick="
        showTwoButtonModal = false;
        searchLot(lot_number_temp);
      "
    ></two-button-modal>
    <div
      class="modal_layer"
      v-if="isProductionLogFormModalOpen"
      @click="productionPrintModalColse"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FetchMixin from '@/mixins/fetch';
import LotSearch from '@/layouts/components/search-popup/LotSearch';
import { formatDate } from '@/utils/filters';
import routes from '@/routes/routes';
import barcodeRead from '@/layouts/components/BarcodeReadModal';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import { getExpireDate, yyyymmdd } from '@/utils/func';
// import ProductionLogFormPrintModal from '@/layouts/components/ProductionLogFormPrintModal';
// import SanChaeProductionLogFormPrintModal from '@/layouts/components/5/ProductionLogFormPrintModal';
// import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';

export default {
  mixins: [SpinnerMixin, ModalMixin, FetchMixin],
  data() {
    return {
      // showSelectric: false,
      showLotSearch: false,
      showBarcodeSearch: false,
      showTwoButtonModal: false,
      lot_number_temp: null,
      isProductionLogFormModalOpen: false,
      // searchType: null,
    };
  },
  components: {
    // MyLocalSelectric,

    LotSearch,
    barcodeRead,
    TwoButtonModal,
    // ProductionLogFormPrintModal,
    // SanChaeProductionLogFormPrintModal,
  },
  computed: {
    ...mapGetters({
      target_lot: 'getTargetLotFromLotTracking',
      tracking_resource: 'getLotTrackingResource',
      tracking_sales: 'getLotTrackingSales',
      radioState: 'getRadioStateFromLotTracking',
      searchText: 'getSearchTextFromLotTracking',
      selectedTab: 'getSelectedTabFromLotTracking',
      unitConversions: 'getUnitConversion',
      products: 'getProduct',
      process: 'getProcess',
      companys: 'getCompany',
      units: 'getUnitCodes',
      materials: 'getMaterial',
      lotTypes: 'getLotType',
      systemCompany: 'getSystemCompany',
      printCompleteList: 'getPrintCompleteListFromPrintComplete',
    }),
    dynamicComponent() {
      if (Number(localStorage.getItem('saupKey')) == 5)
        return () =>
          import('@/layouts/components/5/ProductionLogFormPrintModal.vue');
      else
        return () =>
          import('@/layouts/components/ProductionLogFormPrintModal.vue');
    },
    getStartTime() {
      if (this.target_lot != null && this.target_lot.bom_info != undefined) {
        const BomProcess = this.target_lot.bom_info.bom_process;
        const ProductionInfoList = this.lodash
          .clonedeep(BomProcess.map(x => x.production_info))
          .filter(x => x != null);
        ProductionInfoList.sort((a, b) => {
          Number(
            a.create_time
              .replace(/-/gi, '')
              .replace(/:/gi, '')
              .replace(' ', ''),
          ) -
            Number(
              b.create_time
                .replace(/-/gi, '')
                .replace(/:/gi, '')
                .replace(' ', ''),
            );
        });
        if (ProductionInfoList[0] != null) {
          return formatDate(ProductionInfoList[0].create_time);
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    getEndTime() {
      if (this.target_lot != null && this.target_lot.product_in != undefined) {
        return formatDate(
          this.target_lot.product_in[this.target_lot.product_in.length - 1]
            .create_time,
        );
      } else {
        return '';
      }
    },
  },
  methods: {
    getExpireDate(item) {
      const products = this.findInfoFromId(this.products, item.product_id);
      return yyyymmdd(
        new Date(
          getExpireDate(
            new Date(item.create_time),
            products.expire_year,
            products.expire_month,
            products.expire_day,
          ),
        ),
      );
    },
    getUnitConversion(material_id) {
      const target_mat = this.findInfoFromId(this.materials, material_id);
      const foundItem = this.unitConversions.find(
        x =>
          x.in_unit_id == target_mat.incoming_unit_id &&
          x.out_unit_id == target_mat.using_unit_id,
      );
      if (foundItem != null) {
        return foundItem.cvr_ratio;
      } else {
        return 1;
      }
    },

    checkButton() {
      this.showLotSearch = true;
      this.showBarcodeSearch = false;
    },
    barcodeButton() {
      this.showLotSearch = false;
      this.showBarcodeSearch = true;
    },
    async trackingProductStock(item) {
      const goingPage = routes[0].children.find(
        x => x.path == '/product/status',
      );
      if (goingPage != undefined) {
        goingPage.meta.stock = this.lodash.clonedeep(item);
        await this.$router.push('/product/status');
      }
    },
    async trackingStatus(item) {
      const goingPage = routes[0].children.find(
        x => x.path == `/${item.resource_gubun}/status`,
      );
      if (goingPage != undefined) {
        goingPage.meta.target = this.lodash.clonedeep(item);
        await this.$router.push(`/${item.resource_gubun}/status`);
      }
    },
    typing(e) {
      this.$store.commit('setSearchTextToLotTracking', e.target.value);
    },
    async searchLot(lot_no) {
      if (lot_no.length == 13) {
        let lot_str = lot_no.substr(8);
        lot_str = Number(lot_str);
        this.selectLot({ id: lot_str });
      } else {
        this.openOneButtonModal(
          '올바른 LOT번호를 입력해주세요',
          '작업지시서로 검색할 수 있습니다.',
        );
      }
    },
    async searchProductBarcode(control_no) {
      if (control_no.length == 13) {
        let control_str = control_no.substr(8);
        control_str = Number(control_str);
        if (this.radioState.radio_lot == true) {
          this.selectLot({ id: Number(control_str) });
        } else if (this.radioState.radio_barcode == true) {
          await this.$store
            .dispatch('FIND_LOT_ID_FROM_CONTROL_ID', Number(control_str))
            .then(id => {
              if (id == -1) {
                this.openOneButtonModal(
                  '검색 실패',
                  '입력한 제품 식별표의 생산공정이 조회되지 않습니다.',
                );
              } else {
                this.selectLot({ id: id });
              }
            })
            .catch(() => {
              this.lot_number_temp = control_no;
              this.showTwoButtonModal = true;
            });
        }
      } else {
        this.openOneButtonModal(
          '올바른 LOT번호를 입력해주세요',
          '작업지시서로 검색할 수 있습니다.',
        );
      }
    },
    async selectLot(arg) {
      await this.FETCH_LOT_PRODUCTION_TO_LOT_TRACKING(arg.id);
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_LOT_TRACKING_RESOURCE',
        arg.id,
        '투입',
      );
      await this.FETCH_WITH_PAYLOAD('FETCH_LOT_TRACKING_SALES', arg.id, '납품');
      this.$store.commit('setSelectedTabToLotTracking', 0);
    },
    async FETCH_LOT_PRODUCTION_TO_LOT_TRACKING(lot_id) {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_LOT_PRODUCTION_TO_LOT_TRACKING', lot_id)
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 실패',
            '잘못된 번호입니다. 올바른 번호를 입력해 주세요.',
          );
          this.$store.commit('setTargetLotToLotTracking', null);
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    goBomManagementPage(product_id) {
      const goingPage = routes[0].children.find(x => x.path == '/code/bom');
      if (goingPage != undefined) {
        goingPage.meta.select = product_id;
      }
      this.$router.push('/code/bom');
    },
    getMinPassQuantity(bom_info) {
      if (bom_info != undefined) {
        let max_index = 0;
        bom_info.bom_process.forEach(el => {
          if (el.index > max_index) max_index = el.index;
        });
        console.log('max_index', max_index);
        let min_pass_quantity = 99999999999999;
        const filterLastIndexProcess = this.lodash.clonedeep(
          bom_info.bom_process.filter(x => x.index == max_index),
        );
        filterLastIndexProcess.forEach(el => {
          if (el.production_info == null) min_pass_quantity = 0;
          else if (el.production_info.pass_quantity < min_pass_quantity)
            min_pass_quantity = el.production_info.pass_quantity;
        });

        return min_pass_quantity;
      } else {
        return 0;
      }
    },
    productionPrintModalColse() {
      this.isProductionLogFormModalOpen = false;
    },
    makeProductBarcode(product) {
      const completeList = this.printCompleteList.find(
        x => x.product_control_id == product.product_control_id,
      );

      if (completeList.input_date != undefined) {
        const date = completeList.input_date.replace(/-/gi, '');
        const product_control_id = completeList.product_control_id;
        const fix5 = ('00000' + product_control_id).substr(-5);
        completeList.barcode_num = date + fix5;
        return date + fix5;
      }
    },
  },
  async created() {
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.process.length < 1) {
      await this.FETCH('FETCH_PROCESS', '공정');
    }
    if (this.lotTypes.length < 1) {
      await this.FETCH('FETCH_LOT_TYPE', '작업상태');
    }
    if (this.unitConversions.length < 1) {
      await this.FETCH('FETCH_UNIT_CONVERSION', '단위환산');
    }

    await this.FETCH_WITH_PAYLOAD_NO_SPINNER(
      'FETCH_PRINT_COMPLETE',
      {
        start: '2001-01-01',
        end: yyyymmdd(new Date()),
      },
      '식별표정보',
    );

    if (this.$route.meta.select != undefined) {
      this.selectLot({
        id: this.$route.meta.select,
      });
      delete this.$route.meta.select;
    } else if (this.target_lot != null) {
      await this.FETCH_LOT_PRODUCTION_TO_LOT_TRACKING(this.target_lot.id);
      await this.FETCH_WITH_PAYLOAD(
        'FETCH_LOT_TRACKING_SALES',
        this.target_lot.id,
        '납품',
      );

      await this.FETCH_WITH_PAYLOAD(
        'FETCH_LOT_TRACKING_RESOURCE',
        this.target_lot.id,
        '투입',
      );

      const ProductionInfoList = this.lodash.clonedeep(
        this.target_lot.bom_info.bom_process.map(x => x.production_info),
      );

      if (ProductionInfoList[0] == null || this.getStartTime == null) {
        this.openOneButtonModal(
          '작업지시서 오류',
          '해당 작업지시서의 생산공정이 취소되었습니다.',
        );
        this.$store.commit('setTargetLotToLotTracking', null);
      } else if (
        this.selectedTab == 2 &&
        this.target_lot.product_out[1].length == 0
      ) {
        this.$store.commit('setSelectedTabToLotTracking', 1);
      } else if (
        this.selectedTab == 1 &&
        this.target_lot.product_in == undefined
      ) {
        this.$store.commit('setSelectedTabToLotTracking', 0);
      }
    }
    this.showSelectric = true;
  },
};
</script>

<style lang="scss" scoped></style>
