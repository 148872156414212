<template>
  <div id="contents" class="lot_trace">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="openTabIndex"
          :commit="'setOpenTabIndexToLotTrackingPage'"
          :options="[
            { title: '제품 LOT추적', name: 'lot_tracking' },
            { title: '원가산출', name: 'costing' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            :class="{ active: openTabIndex == 0 }"
            @click="$store.commit('setOpenTabIndexToLotTrackingPage', 0)"
          >
            <a>제품 LOT추적</a>
          </li>
          <li
            :class="{ active: openTabIndex == 1 }"
            @click="$store.commit('setOpenTabIndexToLotTrackingPage', 1)"
          >
            <a>원가산출</a>
          </li>
        </ul>
      </div>
      <lot-tracking-form v-if="openTabIndex == 0"></lot-tracking-form>
      <cost-calculation-form v-if="openTabIndex == 1"></cost-calculation-form>
    </div>
  </div>
</template>

<script>
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import LotTrackingForm from '@/views/forms/Product/Tracking/LotTrackingForm.vue';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import CostCalculationForm from '@/views/forms/Product/Tracking/CostCalculationForm.vue';
import { mapGetters } from 'vuex';
export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    LotTrackingForm,
    CostCalculationForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      openTabIndex: 'getOpenTabIndexFromLotTrackingPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      await this.$store.commit('InitLotTrackingPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss" scoped></style>
